



























// Define the component in class-style
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, VModel } from "vue-property-decorator";
import { value } from "@/models/CustomTypes";

@Component
export default class DateInput extends Vue {
  @Prop({ default: "Data" }) label: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: "month" }) type: string;
  @VModel({ type: [String, Object] }) localValue: value;
  @Prop({ type: Array, default: () => [] }) rules: [];
  modal = false;
}
